html,
body,
#root,
#root>div {
    /* height: 100%; */
    margin: 0;
    padding: 0;
    /* border: 1px solid red; */
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
}

.loader:before,
.loader:after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: #715CA8;
    -webkit-mask: repeating-linear-gradient(#000 0 10px, #0000 0 20px);
}

.loader:after {
    -webkit-mask: repeating-linear-gradient(#0000 0 10px, #000 0 20px);
    animation: l36 1.5s infinite;
}

@keyframes l36 {

    0%,
    10% {
        transform: rotate(0) translate(0)
    }

    35% {
        transform: rotate(0) translate(100%)
    }

    66% {
        transform: rotate(180deg) translate(100%)
    }

    90%,
    100% {
        transform: rotate(180deg) translate(0)
    }
}

.preserveSpaces {
    white-space: pre-line;
}


/* Styles for the Onboarding page */
.onboardingImage {
    width: 415px;
    height: 444px;
}

.onboardingHeading {
    font-weight: 600;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 28px;
    line-height: 35.28px;
}

.onboardingText {
    font-weight: 200;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 17px;
    line-height: 28px;
    opacity: 70%;
}

.carousel-indicators {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.carousel-indicators button {
    width: 10px !important;
    height: 10px !important;
    opacity: 40%;
    /* Set both width and height to the same value for a perfect circle */
    border-radius: 50% !important;
    border: 1px solid #fff;
    background-color: transparent;
    margin: 0 8px;
    cursor: pointer;
}

.carousel-indicators button.active {
    background-color: #fff;
    width: 12px !important;
    height: 12px !important;
}


/* Styles for the Signup page */
.col-6 {
    height: 100vh;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.first {
    background-color: #715CA8;
    display: flex;
}

.second {
    padding: 100px;
}

.petmiLogo {
    width: 191px !important;
    height: 68px !important;
    display: block !important;

}

.createAccountSupText,
.forgetPasswordSupText,
.loginSupText,
.newPasswordSupText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    size: 38px;
    line-height: 33.6px;
    width: 234px;
    height: 34px;
}

.createAccountSubText,
.forgetPasswordSubText,
.loginSubText,
.newPasswordSubText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    width: 481px;
    height: 64px;
    opacity: 80%;
}

.formIcons {
    width: 20px;
    height: 20px;
}

.eyeIcon {
    width: 20px;
    height: 20px;
}


.passwordInput {
    border-right: none !important;
}

.eyeButton {
    border: 0.1px solid rgb(222, 221, 221) !important;
    border-left: 0 !important;
}


.verifyEmailBtn {
    background-color: #564999 !important;
    border-radius: 12px;
    opacity: 50%;
    color: white !important;
}

.verifyEmailBtn:hover {
    background-color: #564999;
    opacity: 95%;
    color: white;
}

.loginBtn,
.resetPasswordBtn {
    /* THIS loginBtn IS FOR THE SIGN IN PAGE. I HAD TO PUT IT HERE BEAUSE FOR SOME REASON, IT CREATES A BUG IF I PUT IT IN THE LOGIN PAGE SECTION OF THIS STLYE. (i think a different style code is clashing, causing it to misbehave) */
    background-color: #564999 !important;
    border-radius: 12px;
    opacity: 50%;
    color: white !important;
    width: 364px !important;
    height: 62px !important;
}

.loginBtn:hover,
.resetPasswordBtn:hover {
    background-color: #564999;
    opacity: 95%;
    color: white;
}

.lastText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    size: 16px;
    line-height: 22.4px;
    color: #3B3B3B;
}

.signInText a {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    size: 16px;
    line-height: 22.4px;
    color: #564999;
    text-decoration: none !important;
}

.filled {
    background-color: #564999;
    opacity: 95%;
    color: white;
}


/* VERIFY OTP PAGE */
.VerifyOtpSupText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 33.6px;
    width: 234px;
    height: 34px;
}


.VerifyOtpSubText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
    opacity: 80%;
}

.verifyOtpInput {
    width: 76px;
    height: 72px;
    border-radius: 10px;
    border: 2px solid #D7DBFC;
    margin-right: 16px;
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 50.4px;
}

.verifyOtpTime {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #5A5A5A;
}

.noOtp {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    /* opacity: 73%; */
    color: #5A5A5A;
}

.resend a {
    font-weight: 600;
    color: #564999;
    text-decoration: none !important;
}


.verifyOtpButton {
    width: 330px;
    height: 52px;
    background-color: #564999 !important;
    border-radius: 12px;
    color: white !important;
    opacity: 50%;
}

.verifyOtpButtonFilled {
    background-color: #564999 !important;
    color: #FFE27C !important;
    opacity: 100%;
}

.verifyOtpButton:hover {
    background-color: #564999;
    color: #FFE27C !important;
    opacity: 100%;
}


/* Login Page */
.forgotPassword a {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    color: #715CA8;
    text-decoration: none;
}

.forgotPasswordLink:hover {
    color: #663fca;
}


/* MANAGE ADS PAGE */
#sidebar {
    min-height: 100vh;
}



.petmiLogo2 {
    width: 129px !important;
    height: 47px !important;
    top: 52px;
    left: 30px;
    display: block !important;

}

.sideLink {
    margin-top: 30px;
    color: #09090A;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    size: 18px;
    line-height: 25.2px;
    /* text-decoration: none !important; */
}

.activeSideLink a {
    background-color: #6C59A6;
    color: #FFFFFF;
}

.activeSideLink a:hover {
    background-color: #6C59A6;
    color: #FFFFFF;
}

.adsIcon {
    width: 18px;
    height: 18px;
    top: 3px;
    left: 3px;
}

.navRow {
    height: 9lvh;
}


.searchInput {
    background-color: #F5F4F6;
    border-radius: 19px 0 3px 19px;
    height: 40px !important;
    width: 538px;
    /* border: none !important; */
    /* border-radius: 10%; */
}

.searchInput::placeholder {
    width: 57.41px !important;
    height: 19px;
    top: 54px;
    left: 372.4px;
    size: 14px !important;
    opacity: 70%;
}

.searchIconBtn {
    border: none !important;
    background-color: #F5F4F6;
    border-radius: 0px 19px 19px 0px;
}

.searchIcon {
    color: #5041BC;
}

.outterRightNavDiv {
    height: 6lvh;
}

.notificationIconDiv {
    background-color: #F5F4F6;
    /* width: 40px; */
    height: 40px;
    left: 1080px;
    top: 40px;
    border-radius: 50px;
    padding: 8px;

}

.notificationIcon {
    width: 24px;
    height: 24px;
}

.notificationDot {
    color: #C82530;
    font-size: 32px;
}

.personAvatar {
    width: 40px;
    height: 40px;
}

.personName {
    width: 104px;
    height: 16px;
    top: 2px;
    left: 48px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #4C4C4C;

}

.rightNav {
    display: flex;
    height: 10lvh;
    padding-top: 5.5px;
}

.rigtNavInnerDiv {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.personRole {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #B3B3B3;
}

hr {
    color: #546dbe;
    top: 112.21px;
    left: 0.01px;
    width: 111%;
    /* margin-left: 22px; */
}

.closeSearchResult {
    border: none !important;
    background-color: inherit;
    font-size: 23px;
    font-weight: bolder;
    color: red;
}

.closeSearchResult:hover {
    border: none !important;
    background-color: inherit;
    font-size: 30px;
    font-weight: bolder;
}

#adStatusChangeError {
    display: none;
}

#resourceStatusChangeError {
    display: none;
}

.timeframeDiv {
    background-color: #F5F4F6;
    width: 174px;
    height: 30px;
    top: 216px;
    left: 345px;
    /* padding-left: 23px; */
    border-radius: 10px;
    gap: 11px;
}

.timeframeDiv button {
    border: none !important;
    color: black;
}

.timeframe {
    width: 28px;
    height: 13px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 12.6px;
    color: #5F6165;
}

.timeframeActive {
    color: white;
    background-color: #EEB12D;
    padding: 8px;
    padding-right: 12px;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    border-top-right-radius: 18px;
}

.timeframeActive button {
    border: none !important;
    color: white;
    background-color: inherit;
}


.colTitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500px;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    padding-left: 52px !important;
}

.arrowFilter {
    width: 9.85px;
    font-size: 12px;
    padding-left: 2px;
}

.tableBody {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.64px;
}

/* .tableRow{
    margin-bottom: 34px !important;
} */

.tableLinks {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    outline: none;
}

.tableData {
    padding-left: 47px !important;
    padding-top: 17px !important;
}

.tableDataBtn {
    border: none !important;
    background-color: inherit;
}

.tableDataBtn:hover {
    color: #3d83ed9e;
}

.buttonDrop {
    width: 117px !important;
    height: 21px !important;
    font-size: 14px !important;
    background-color: white;
    border: none;
    color: black;
    /* padding-bottom: 9px !important; */

}

.pendingSpan {
    width: 117px !important;
    height: 21px !important;
    border-radius: 12px !important;
    color: #3d83ed9e;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 4px;
    padding-bottom: 4px;
    gap: 10px;
    background-color: #c0d8fc !important;
}

.chevronButton {
    border: none !important;
    background-color: white;

}

.chevronButtonRight {
    border: none !important;
    margin-right: 34px;
}

.Chevronn {
    background-color: white;
    border: none !important;
}

.chevronSpan {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 20.16px;
}




/* AD DETAILS PAGE */
.adDetailsDiv {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 10px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 2;
    filter: blur(0px);
    /* width: 781px; */
    /* height: 673px; */
    width: 54%;
    height: 91.5%;
    position: absolute;
    /* Use absolute positioning */
    top: 10%;
    left: 30%;
    overflow-y: auto;
    /* Allow vertical scrolling */
}

.manageAdsDiv {
    filter: blur(2px);
}

.cancelPopupBtn {
    border: none;
    background-color: inherit;
    color: #787486;
    font-size: 40px;
}

.popupCircleImg {
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.adPoster {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600px;
    font-size: 18px;
    line-height: 21.6px;
    color: #3B3B3B;
    font-weight: bold;
}

.adsPrice {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #564999;
    /* font-weight: bolder */
}

.adType {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400px;
    font-size: 14px;
    line-height: 22.4px;
    color: #3B3B3BCC;
}

.adsLocation {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300px;
    font-size: 12px;
    line-height: 16px;
    color: #3B3B3BB2;

}

.heroPhotoUrlDiv {
    display: flex;
    justify-content: center;
    align-content: center;
    /* width: 100%; */
    /* max-width: 100%; */
    /* object-fit: contain; */
    /* border: 2px solid red; */
}

.adsBigImage {
    display: flex;
    max-width: 92vh;
    max-height: 50vh;
    top: 20px;
    left: 55px;
}

.overlay-text {
    width: 80px;
    height: 23px;
    border-radius: 6px;
    padding: 5px 11px 5px 11px;
    position: absolute;
    top: 39%;
    left: 83%;
    transform: translate(-50%, -50%);
    background-color: #ED9D02;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15.12px;
    color: #FFFFFF;
}

.adsAnimalBreed {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #3B3B3B;
}

.adsAboutText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.64px;
    color: #3B3B3BCC;
}

.adsFilter {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15.12px;
    color: #ED9D02;
    background-color: #fdf0d7;
    margin-right: 14px;
    width: 104px;
    height: 28px;
    border-radius: 4px;
    border: 0.5px solid #ED9D02;
    padding: 10px 8px 10px 8px;



    /* font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 15.12px;
    color: #c1bdbd;
    background-color: #DADADA;
    margin-right: 14px;
    width: 104px;
    height: 28px;
    border-radius: 4px;
    border: 0.5px solid #DADADA;
    padding: 10px 32px 10px 32px; */
}



/* ARTICLES/SM POSTS PAGE */
/* .sidebarArticles{
    height: 100vh;
} */
.newArticleBtn {
    border: none;
    background-color: #564999;
    opacity: 50%;
    color: white;
}

.newArticleBtn:hover {
    border: none;
    background-color: #564999;
    opacity: 98%;
    color: white;
}

.deleteIcon {
    color: #EC2929;
    font-size: 20px;
    padding-left: 18px;
}

.colTitleArticle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500px;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.tableDataArticle {
    padding-top: 17px !important;
}


.newArticlePostDiv {
    width: 781px;
    height: 880px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 10px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 2;
    filter: blur(0px);
    position: absolute;
    /* Use absolute positioning */
    top: 2%;
    left: 30%;
    overflow-y: auto;
    /* Allow vertical scrolling */
}

.outerAdImgDiv {
    border: 2px dotted #D9D9D9;
    width: 676px;
    height: 240.82px;
    border-radius: 5px;
}

.innerAdImgDiv {
    border: 2px dotted #D9D9D9;
    width: 624px;
    height: 190.43px;
    border-radius: 5px;
    left: 380px;
}

.newArticlePostText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 21px; */
    color: #3B3B3B;
}

.inputHeader {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 13px;
    /* line-height: 21px; */
    color: #3B3B3B;
}


.addImagePlusDiv {
    padding-top: 4px;
    margin-top: 20px;
    width: 46px;
    height: 46px;
    border-radius: 50% !important;
    background-color: #dcd6d6;
}

.addImage {
    font-size: 24px;
    height: 24px;
    /* margin-right: 3px; */
    /* top: 198.94px;
    left: 5px; */
    color: #9E9A9A;
}

.adImageInnerP {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #3B3B3B;
}

.adImageInnerWarning {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;
    color: #3B3B3B;
}

#fileIndicator {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #3B3B3B;
}

.articleInputFeild::placeholder {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #DADADA;
}

.inputBelowWarning {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300;
    font-size: 9px;
    line-height: 20px;
    color: #3B3B3B;
}

.tagsDiv {
    display: inline-block;
}

.articleFilter {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 15.12px;
    color: #c1bdbd;
    background-color: #DADADA;
    margin-right: 14px;
    width: 104px;
    height: 28px;
    border-radius: 4px;
    border: 0.5px solid #DADADA;
    padding: 10px 32px 10px 32px;
}

.articleFilterBtn {
    background-color: #DADADA;
}

.articleFilterBtn:hover {
    background-color: #DADADA !important;
}

.tagssss[disabled] {
    color: gray;
    pointer-events: none;
}

.addArticlePostBtn {
    color: white;
    width: 200px;
    height: 40px;
    border-radius: 8px;
    background-color: #564999;
    opacity: 90%;
}

.addArticlePostBtn:hover {
    color: white;
    width: 200px;
    height: 40px;
    border-radius: 8px;
    background-color: #564999;
    opacity: 50%;
}

/* Error Page */
.error404ImgDiv {
    display: flex;
    justify-content: center;
    align-content: center;
}

.error404Img {
    display: flex;
    max-width: 50vh;
}